import './css/App.css';
import Navbar from './layout/Navbar.js';
import Content from './layout/Content.js';


export default function App() {
  return (
    <>
      <Navbar></Navbar>
      <Content></Content>
    </>
  );
}
